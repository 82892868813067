export enum EJavaBusinessResponseCode {
  NOT_LOGIN = 101,
  NOT_FOUND = 404,
  NOT_FOUND_4004 = 4004,
  SERVER_ERROR = 500,
  SERVER_ERROR_999 = 999,
  MACHINE_VERIFICATION = 106523,
  NOT_AUTHENTICATION = 5555,
  NOT_FULL_EIGHTEEN = 5556,
  INVALID_LOGIN = 51007,
  PASSWORD_ERROR_MACHINE_VERIFICATION = 2009, // 密码错误多次
  REMOTE_LOGIN_MACHINE_VERIFICATION = 2010, // 异地登录
  MACHINE_VERIFICATION_OVERTIME = 2001, // 滑动校验超时
  MACHINE_VERIFICATION_INVALID = 2002 // 滑动验证token无效
}

export enum EPHPBusinessResponseCode {
  NOT_LOGIN = 401,
  NOT_FOUND = 404,
  NOT_FOUND_4004 = 4004,
  SERVER_ERROR = 500,
  SERVER_ERROR_999 = 999,
  MACHINE_VERIFICATION = 106523,
}

export type TRequestOption = {
  method: any;
  open?: boolean;
}
